:root{
  --primary-color: #ff3426;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.suse {
  font-family: "SUSE", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.suse-500 {
  font-family: "SUSE", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.suse-600 {
  font-family: "SUSE", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.suse-700 {
  font-family: "SUSE", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.suse-800 {
  font-family: "SUSE", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}

.menu a {
  color: black;
}

.centralizar {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-color: white;
  color: black;
}

.page-title {
  padding: 50px;
}

.title {
  margin-left: 10px;
  font-size: 40px;
  color: var(--primary-color);;
  text-shadow: 
    -1px -1px 0px black,
    1px -1px 0px black,
    -1px 1px 0px black,
    1px 1px 0px black;
}

.navbar {
  border-bottom: 1px solid #ddd;
}

.navbar-brand img {
  max-height: 50px; /* Ajuste a altura da logo se necessário */
}

.navbar-nav {
  flex-direction: row;
}

.navbar-nav .nav-link {
  padding-left: 1rem;
  padding-right: 1rem;
}

.navbar-nav .nav-link:hover {
  color: var(--primary-color);;
}

.nav-link {
  display: flex;
  align-items: center;
}

.nav-link svg {
  font-size: 1.5rem; /* Ajuste o tamanho dos ícones se necessário */
}

.carousel-item img {
  height: 500px; /* Ajuste a altura das imagens do carrossel se necessário */
  object-fit: cover; /* Garante que a imagem cubra o espaço sem distorção */
}

.carousel-caption h3 {
  color: #fff; /* Ajuste a cor do título da legenda se necessário */
}

.carousel-caption p {
  color: #fff; /* Ajuste a cor da descrição da legenda se necessário */
}

.icon-gas-station {
  color: var(--primary-color);;
  font-size: 60px;
}

.highlights-row {
  padding: 50px;
  text-align: center;
  z-index: 2;
  position: relative;
}

.highlights-row-internal {
  padding: 20px !important;
}

.highlights-col {
  padding: 40px;
  border-radius: 25px;
  border: 3px solid #CCC;
  background: linear-gradient(to top, #EEE, #FFF);
}

.highlights-text {
  padding: 10px;
  text-align: justify;
  text-align: center;
  font-size: 18px;
}

.highlights-background {
  position: relative;
  margin-top: 50px !important;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.highlights-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(126, 0, 0, 0.5);
  z-index: 1;
}

.social-icons a {
  color: black;
}

.social-icons a:hover {
  color: var(--primary-color);;
}

.about {
  font-size: 18px;
  justify-content: center;
  margin-bottom: 50px;
}

.about-row {
  padding: 50px;
  text-align: center;
}

.image {
  max-width: 100%;
}

.about-col {
  display: flex;
  align-items: center;
  text-align: justify;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.padding-50 {
  padding: 50px !important;
}

/* CELULAR */
@media (max-width: 767.98px) {
  .title {
    font-size: 25px;
  }

  .highlights-row {
    padding: 20px;
  }

  .highlights-background {
    margin-top: 10px !important;
  }

  .mobile-margin-0 {
    margin: 0 !important;
  }

  .mobile-padding-0 {
    padding: 0 !important;
  }

  .mobile-padding-25 {
    padding: 25px !important;
  }

  .icon-gas-station {
    font-size: 40px;
  }

  .mobile-margin-top-50 {
    margin-top: 50px;
  }
  
  .mobile-margin-bottom-50 {
    margin-bottom: 50px;
  }

  .mobile-margin-top-0 {
    margin-top: 0 !important;
  }

  .instagram {
    display: none;
  }

  .about-col {
    margin-top: 50px !important;
  }
}